import React from "react"
import { Link } from 'gatsby'

import * as style from './footer.module.scss'
import logo from '../images/logo-white.svg'
import facebook from '../images/icons/facebook.svg'
import twitter from '../images/icons/twitter.svg'
import instagram from '../images/icons/instagram.svg'
import youtube from '../images/icons/youtube.svg'
import linkedin from '../images/icons/linkedin.svg'

const Footer = () => {
  return (
    <footer className={style.footer}>
      <div className={style.footerWrap}>
        <div>
          <Link to='/' className={style.logo}>
            <img src={logo} alt="Mediahub" />
          </Link>
          <nav>
            <ul className={style.footerMenu}>
              <li><Link to='/about/'>O nas</Link></li>
              <li><Link to='/work/'>Nasze prace</Link></li>

              <li><Link to='/news/'>Aktualności</Link></li>

              <li><Link to='/contact/'>Kontakt</Link></li>
              <li><Link to='/regulamin-serwisu/'>Regulamin serwisu</Link></li>
              <li><Link to='/polityka-prywatnosci/'>Polityka prywatności</Link></li>
            </ul>
          </nav>
        </div>
        <ul className={style.socialMenu}>
          <li><a href="https://www.facebook.com/MediahubPoland" target="_blank" rel="noreferrer"><img src={facebook} alt="Facebook" height="60" /></a></li>
          <li><a href="https://www.instagram.com/mediahubpoland/" target="_blank" rel="noreferrer"><img src={instagram} alt="Instagram" height="60" /></a></li>
          <li><a href="https://www.linkedin.com/company/mullenlowe-poland/" target="_blank" rel="noreferrer"><img src={linkedin} alt="LinkedIn" height="60" /></a></li>
        </ul>
        <p className={style.legal}>&copy; Mediahub Worldwide {new Date().getFullYear()}</p>
      </div>
    </footer>
  )
}

export default Footer
