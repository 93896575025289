/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import Footer from "./footer"
import CookieConsent, { Cookies } from "react-cookie-consent";
import { useLocation } from "@reach/router" // this helps tracking the location
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import queryString from 'query-string'

import "../styles/layout.scss"
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";



const Layout = ({ children, id, darkMode }) => {

  const location = useLocation();

  

  const params = new URLSearchParams(location.search);

  console.log("params",params);
  console.log("x", params['x']);

  return (
    <>
      <Header darkMode={darkMode} />
      <main id={id}>
        {children}
      </main>
      <Footer />
      <CookieConsent
          enableDeclineButton
          location="bottom"
          buttonText="AKCEPTUJĘ"
          declineButtonText="ODRZUĆ"
          style={{ background: "#000000" }}
          cookieName="gatsby-gdpr-google-tagmanager" 
          declineButtonStyle={{ color: "#ffffff", fontSize: "13px", background: "#000000", border: "1px solid #ffffff" }}
          style={{ background: "#000000" }}
          
          buttonStyle={{ color: "#ffffff", fontSize: "13px", background: "#df1995" }}
          onAccept={() => {
            //Cookies.set("gatsby-gdpr-google-tagmanager", true);
            
            initializeAndTrack(location);

          }}
          >
Nasza strona wykorzystuje pliki cookies w celu prawidłowego działania strony, korzystania z narzędzi marketingowych i analitycznych. Szczegółowe informacje znajdziesz w naszej <a href="/polityka-prywatnosci/" target="_blank" className={"cookie-privacy-policy-link"}>Polityki Prywatności i Cookies</a>. Akceptując, zgadzasz na wykorzystywanie cookies.
</CookieConsent>
   
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  darkMode: PropTypes.bool
}

export default Layout
