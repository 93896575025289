import React from 'react'
import { Link } from 'gatsby'
import Headroom from 'react-headroom'

import * as style from './header.module.scss'
import Medium1 from '../images/blobs/medium1.inline.svg'
import logoBlack from '../images/logo.svg'
import logoWhite from '../images/logo-white.svg'
import menuBlack from '../images/icons/menu.svg'
import menuWhite from '../images/icons/menu-white.svg'
import closeBlack from '../images/icons/close.svg'
import closeWhite from '../images/icons/close-white.svg'

class Header extends React.Component {
  constructor() {
    super()
    this.state = {
      active: false
    }
  }

  componentWillUnmount() {
    document.body.classList.remove('no-scroll')
  }

  handleClick() {
    if (this.state.active) {
      document.body.classList.remove('no-scroll')
    } else {
      document.body.classList.add('no-scroll')
    }

    this.setState(prevState => ({
      active: !prevState.active
    }))
  }

  render() {
    const headerClasses = [style.wrap]
    if (this.props.darkMode && !this.state.active) {
      headerClasses.push(style.darkMode)
    }
    const navClass = this.state.active ? style.navActive : style.nav
    const iconBlack  = this.state.active ? closeBlack : menuBlack
    const iconWhite  = this.state.active ? closeWhite : menuWhite
    const label = this.state.active ? 'Zamknij' : 'Menu'

    return (
      <>
        <Headroom>
          <div className={headerClasses.join(' ')}>
            <Link to="/" className={style.logo}>
              <img src={logoBlack} alt="Mediahub logo" className={style.logoBlack} />
              <img src={logoWhite} alt="Mediahub logo" className={style.logoWhite} />
            </Link>
            <button className={style.hamburger} onClick={() => this.handleClick()}>
              <img src={iconBlack} alt={`${label} icon`} className={style.iconBlack} />
              <img src={iconWhite} alt={`${label} icon`} className={style.iconWhite} />
              <span className="hidden-mobile">{label}</span>
            </button>
          </div>
        </Headroom>
        <nav className={navClass}>
          <ul>
            <li><Link to="/" activeClassName={style.linkActive}><sup>01</sup>Start</Link></li>
            <li><Link to="/about/" activeClassName={style.linkActive}><sup>02</sup>O Nas</Link></li>
            <li><Link to="/work/" activeClassName={style.linkActive}><sup>03</sup>Nasze prace</Link></li>

            <li><Link to="/news/" activeClassName={style.linkActive}><sup>04</sup>Aktualności</Link></li>
            <li><Link to="/contact/" activeClassName={style.linkActive}><sup>05</sup>Kontakt</Link></li>
          </ul>
          <div className={style.blob}>
            <Medium1 />
          </div>
        </nav>
      </>
    )
  }
}

export default Header
